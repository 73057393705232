import { addEventListenerOnce } from '../utils/event-listener';

let $navigation = null;
let $dropdowns = null;
let opened = false;

/**
 * Eventlistener for sidr closing
 *
 * @returns {void}
 */
const eventListener = () => {
    const $burger = $navigation.querySelector('.page-menu__burger');
    const $badge = $burger && $burger.querySelector('#burger-badge');
    const $overlay = document.querySelector('.page-menu__overlay');
    const $wrapper = $navigation.querySelector('.page-menu__wrapper');

    if ($burger && $badge && $overlay) {
        $burger.addEventListener('click', () => {
            if (opened) {
                addEventListenerOnce($overlay, 'transitionend', () => {
                    $overlay.style.display = 'none';
                });

                $wrapper.classList.remove('page-menu--show');
                $badge.classList.remove('open');
                $overlay.classList.remove('page-menu__overlay--show');

                opened = false;
            } else {
                $badge.classList.add('open');
                $overlay.style.display = 'block';

                window.requestAnimationFrame(() =>
                    $overlay.classList.add('page-menu__overlay--show')
                );

                $wrapper.classList.add('page-menu--show');

                opened = true;
            }
        });
    }
};

/**
 * Initiliaze the mobile navigation
 *
 * @returns {void}
 */
export const init = () => {
    $navigation = document.querySelector('.page-menu');

    if ($navigation) {
        eventListener();
    }
};
