// import './utils/console';

import runtime from 'serviceworker-webpack-plugin/lib/runtime';
import './polyfills';
import './assets-loader';

const isDevelopment = process.env.NODE_ENV === 'development';

let refreshing = false;

if ('serviceWorker' in navigator && !isDevelopment) {
    navigator.serviceWorker.addEventListener('controllerchange', function () {
        if (refreshing) {
            return;
        }

        refreshing = true;
        window.location.reload();
    });

    window.addEventListener('load', () => runtime.register());
}

import * as contentScroll from './components/content-scroll';
import * as mobileNavigation from './components/navigation-mobile';
import * as slider from './components/slider';
import * as lightbox from './components/lightbox';

contentScroll.init();
mobileNavigation.init();
slider.init();
lightbox.init();
