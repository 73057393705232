export const init = () => {
  const $open = document.querySelectorAll('.js-lightbox-image');
  const $close = document.querySelectorAll('.js-lightbox-close');
  const $prev = document.querySelectorAll('.js-lightbox-prev');
  const $next = document.querySelectorAll('.js-lightbox-next');
  const $lightbox = document.querySelector('#js-lightbox');
  const $lightboxImage = document.querySelector('#js-lightbox-image');

  Array.prototype.forEach.call($open, $element =>
    $element.addEventListener('click', () => {
      $element.classList.add('js-lightbox-active-item');
      $lightboxImage.src = $element.getAttribute('data-lightbox-image');
      $lightboxImage.alt = $element.getAttribute('data-lightbox-alt');
      $lightbox.classList.add('js-active');
    })
  );

  Array.prototype.forEach.call($close, $element =>
    $element.addEventListener('click', () => {
      const $activeImage = document.querySelector('.js-lightbox-active-item');
      $activeImage.classList.remove('js-lightbox-active-item');
      $lightboxImage.src = '';
      $lightboxImage.alt = '';
      $lightbox.classList.remove('js-active');
    })
  );

  Array.prototype.forEach.call($next, $element =>
    $element.addEventListener('click', () => {
      const $activeImage = document.querySelector('.js-lightbox-active-item');
      if ($activeImage === $activeImage.parentNode.lastElementChild) {
        const $nextImage = $activeImage.parentNode.firstElementChild;
        $activeImage.classList.remove('js-lightbox-active-item');
        $nextImage.classList.add('js-lightbox-active-item');
        $lightboxImage.src = $nextImage.getAttribute('data-lightbox-image');
        $lightboxImage.alt = $nextImage.getAttribute('data-lightbox-alt');
      }
      else {
        const $nextImage = $activeImage.nextElementSibling;
        $activeImage.classList.remove('js-lightbox-active-item');
        $nextImage.classList.add('js-lightbox-active-item');
        $lightboxImage.src = $nextImage.getAttribute('data-lightbox-image');
        $lightboxImage.alt = $nextImage.getAttribute('data-lightbox-alt');
      }
    })
  );

  Array.prototype.forEach.call($prev, $element =>
    $element.addEventListener('click', () => {
      const $activeImage = document.querySelector('.js-lightbox-active-item');
      if ($activeImage === $activeImage.parentNode.firstElementChild) {
        const $prevImage = $activeImage.parentNode.lastElementChild;
        $activeImage.classList.remove('js-lightbox-active-item');
        $prevImage.classList.add('js-lightbox-active-item');
        $lightboxImage.src = $prevImage.getAttribute('data-lightbox-image');
        $lightboxImage.alt = $prevImage.getAttribute('data-lightbox-alt');
      }
      else {
        const $prevImage = $activeImage.previousElementSibling;
        $activeImage.classList.remove('js-lightbox-active-item');
        $prevImage.classList.add('js-lightbox-active-item');
        $lightboxImage.src = $prevImage.getAttribute('data-lightbox-image');
        $lightboxImage.alt = $prevImage.getAttribute('data-lightbox-alt');
      }
    })
  );
};
