export const init = () => {
  const $toggleImage = document.querySelectorAll('.js-toggle-image');
  Array.prototype.forEach.call($toggleImage, $element =>
    $element.addEventListener('click', () => {
      //hide all active images, show clicked image
      $element.parentNode.parentNode.querySelector('.js-image.js-active').classList.remove('js-active');
      document.querySelector(`#${$element.getAttribute('data-image')}`).classList.add('js-active');
      //unmark all active dots, mark clicked dot
      $element.parentNode.querySelector('.js-active').classList.remove('js-active');
      $element.classList.add('js-active');
    })
  );
};
